<template>
	<page-title
        title="ページ一覧"
        icon="bi-list-columns-reverse"
    >
    </page-title>

    <section class="section">
        <table class="table">
            <thead>
                <tr>
                    <th class="col-2">画面ID</th>
                    <th>画面名</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>A101</td>
                    <td>
                        <router-link :to="{name: 'Signin'}">
                            ログイン
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A201</td>
                    <td>
                        <router-link :to="{name: 'Schedule'}">
                            スケジュール一覧
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A202</td>
                    <td>
                        <router-link :to="{name: 'UndecidedSchedule'}">
                            日時未定予定一覧
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A203</td>
                    <td>
                        <router-link :to="{name: 'AlertUndecidedSchedule'}">
                            日時未定予定アラート
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A301</td>
                    <td>
                        <router-link :to="{name: 'SchoolList'}">
                            学校一覧
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A302</td>
                    <td>
                        <router-link :to="{name: 'SchoolAdd'}">
                            学校登録
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A303</td>
                    <td>
                        <router-link :to="{name: 'SchoolEdit', params:{id: 1}}">
                            学校編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A401</td>
                    <td>
                        <router-link :to="{name: 'Event', params:{id: 1}}">
                            学校別行事一覧
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A501</td>
                    <td>
                        <router-link :to="{name: 'EmployeeList'}">
                            社員一覧
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A502</td>
                    <td>
                        <router-link :to="{name: 'EmployeeAdd'}">
                            社員登録
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A503</td>
                    <td>
                        <router-link :to="{name: 'EmployeeEdit', params:{id: 1}}">
                            社員編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A601</td>
                    <td>
                        <router-link :to="{name: 'PartnerList'}">
                            応援カメラマン一覧
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A602</td>
                    <td>
                        <router-link :to="{name: 'PartnerAdd'}">
                            応援カメラマン登録
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A603</td>
                    <td>
                        <router-link :to="{name: 'PartnerEdit', params:{id: 1}}">
                            応援カメラマン編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A701</td>
                    <td>
                        <router-link :to="{name: 'Skill'}">
                            スキル一覧
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A801</td>
                    <td>
                        <router-link :to="{name: 'Car'}">
                            社用車一覧
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A901</td>
                    <td>
                        <router-link :to="{name: 'Equipment'}">
                            備品一覧
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>A1001</td>
                    <td>
                        <router-link :to="{name: 'PartnerPassword'}">
                            応援カメラマン用パスワード管理
                        </router-link>
                    </td>
                </tr>
            </tbody>
            <tfoot></tfoot>
        </table>

    </section>
</template>

<script>
import PageTitle from '@/components/PageTitle';

export default {
    name: 'Home',
    data() {
        return {}
    },
    components: {
        PageTitle
    }
}
</script>

<style scoped>

</style>
